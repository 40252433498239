<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_stockInquiry" />
      <!-- 検索項目 -->
      <v-form ref="form">
        <v-container fluid>
          <!-- １行目スタート -->
          <v-row class="search-row">
            <!-- 取引先 -->
            <div
              class="search-autocomplete search-autocomplete-supplierList first-search-item"
              style="float: left"
            >
              <v-autocomplete
                dense
                id="get-supplier"
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :rules="[rules.supplierRequired]"
                persistent-hint
                :hint="setSrhSupplier()"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
            <span class="require" style="float: left">*</span>

            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 商品コード -->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                v-model="nameSelected"
                :items="nameList"
                @change="(event) => changeProductNm(event)"
                :hint="checkProductCd()"
                :search-input.sync="search"
                :label="$t('label.lbl_productCnCd')"
                persistent-hint
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 入荷ステータス-->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                v-model="qualityDivSelected"
                :items="qualityDivList"
                :label="$t('label.lbl_arrivalStatus')"
                class="txt-single"
                persistent-hint
                ><template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 在庫有無 -->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                v-model="inStockSelected"
                :items="inStockList"
                :label="$t('label.lbl_inStock')"
                :rules="[rules.inputRequired]"
                class="txt-single"
                persistent-hint
                ><template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
            <span class="require" style="float: left">*</span>

            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn
                  color="primary"
                  id="get-search"
                  class="api-btn"
                  @click="apigetStock('get-search')"
                  >{{ $t("btn.btn_search") }}</v-btn
                >
              </div>
              <!--画面クリアボタン-->
              <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              <div class="btn-search-area" style="float: right">
                <!-- データ出力ボタン -->
                <v-btn color="primary" id="get-outPut" class="api-btn" @click="getOutPut">{{
                  $t("btn.btn_outPutData")
                }}</v-btn>
              </div>
            </div>
          </v-row>

          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>
              <!-- 検索時間表示部分 -->
              <div class="text-label" style="float: left">
                <span v-if="inputList.length != 0" class="nowTime">
                  （{{ today }} {{ nowTime }}時点）
                </span>
              </div>
              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>
                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                      {{ 10 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ 50 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ 100 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
          <!-- 2行目ここまで -->
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          :options.sync="sortOptions"
          :class="{ minheight: u_10 }"
          height="600px"
        >
          <!-- 商品コード・品名 -->
          <template v-slot:[`item.productCnCd`]="{ item }">
            <div v-if="item.productName.length > 30">
              <div v-if="item.incidental">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" style="max-width: 200px" v-bind="attrs" v-on="on">
                      {{ item.productCnCd }}<br />
                      {{ item.incidental }}<br />
                      {{ item.productName }}
                    </div>
                  </template>
                  <div>{{ item.productCnCd }}</div>
                  <div>{{ item.incidental }}</div>
                  <div>{{ item.productName }}</div>
                </v-tooltip>
              </div>
              <div v-else>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" style="max-width: 200px" v-bind="attrs" v-on="on">
                      {{ item.productCnCd }}<br />
                      {{ item.productName }}
                    </div>
                  </template>
                  <div>{{ item.productCnCd }}</div>
                  <div>{{ item.productName }}</div>
                </v-tooltip>
              </div>
            </div>
            <div v-else>
              <div>{{ item.productCnCd }}</div>
              <div>{{ item.incidental }}</div>
              <div>{{ item.productName }}</div>
            </div>
          </template>
          <!-- * -->
          <!-- 在庫ポップアップボタン -->
          <template v-slot:[`item.strageQty`]="{ item }">
            <v-btn color="blue darken-1" text @click="openUpdateDelete(item)">
              <span class="strageQty">{{ item.strageQty }}</span>
            </v-btn>
          </template>
          <template v-slot:[`item.strageQty`]="{ item }">
            <v-btn color="blue darken-1" text @click="openUpdateDelete(item)">
              <span class="strageQty">{{ item.strageQty }}</span>
            </v-btn>
          </template>
        </v-data-table>
        <!-- 詳細画面ポップアップ -->
        <v-dialog v-model="dialogDetails" :max-width="2000">
          <StockInquiryDetails
            id="detailsDialig"
            :isOpenDetailDialog.sync="dialogDetails"
            :entity="detailDialogData"
            :hoge1="dummy_input.arrivalScheduleNo"
            class="overflow"
            v-on:closePopup="closePopup"
          />
        </v-dialog>
        <v-dialog v-model="dataOutPutDialog" :max-width="350" origin:top>
          <!-- データ出力用ダイアログ -->
          <v-card>
            <v-card-title id="sub-bar">
              <span id="lbl-screen-name">{{ $t("label.lbl_stock_outPut") }}</span>
            </v-card-title>
            <v-card-text>
              <v-container fluid>
                <v-form ref="numIssueVali" lazy-validation>
                  <v-row class="search-row" style="margin-bottom: 5px">
                    <!--データ形式-->
                    <div class="search-autocomplete first-search-item" style="width: 270px">
                      <v-autocomplete
                        dense
                        v-model="dataFormatSelected"
                        :items="dataFormatList"
                        :label="$t('label.lbl_outputFormatDiv')"
                        :rules="[rules.dataFormatRules]"
                        class="txt-single"
                      >
                        <template slot="item" slot-scope="data">
                          <span class="auto-txt-size"> {{ data.item.text }} </span></template
                        >
                      </v-autocomplete>
                    </div>
                    <span class="require">*</span>
                    <!--データ形式-->
                  </v-row>
                  <v-row class="search-row" style="margin-bottom: 5px">
                    <!--出力単位-->
                    <div class="search-autocomplete first-search-item" style="width: 270px">
                      <v-autocomplete
                        dense
                        v-model="outPutPatternSelected"
                        :items="outPutPatternList"
                        :label="$t('label.lbl_outPut_pattern')"
                        :rules="[rules.outPutPatternRules]"
                        class="txt-single"
                        persistent-hint
                        ><template slot="item" slot-scope="data">
                          <span class="auto-txt-size"> {{ data.item.text }} </span></template
                        >
                      </v-autocomplete>
                    </div>
                    <span class="require">*</span>
                    <!--出力単位-->
                  </v-row>
                  <v-row style="margin-bottom: 5px">
                    <div class="search-row-exeBtn">
                      <div class="btn-area">
                        <!-- 閉じるボタン -->
                        <v-btn
                          color="primary"
                          id="btn-search"
                          class="other-btn"
                          @click="closeDetailsInfo()"
                        >
                          {{ $t("btn.btn_close") }}</v-btn
                        >

                        <!-- 出力ボタン -->
                        <v-btn
                          color="primary"
                          id="btn-search"
                          class="other-btn"
                          @click="outPutClick()"
                        >
                          {{ $t("btn.btn_outPutData") }}</v-btn
                        >
                      </div>
                    </div>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>

        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>
      <!-- ナビゲーション -->
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { i18n } from "../../lang/lang.js";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import StockInquiryDetails from "../stock/StockInquiryDetails.vue";
import NavBar from "../../components/NavBar.vue";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import SimpleDialog from "@/components/SimpleDialog";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import ConfirmDialog from "@/components/ConfirmDialog";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.SCREEN_ID.P_SRG_001,
  components: {
    Loading,
    sideMenu,
    StockInquiryDetails,
    NavBar,
    SimpleDialog,
    ConfirmDialog,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    u_10: false,
    // 詳細画面へ渡す
    detailDialogData: {
      suppliersSelectedName: "",
      qualityDiv: "",
      itemName: "",
      itemStandardSid: "",
    },
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: true,
    },
    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
      okAction: () => {},
    },
    rules: {
      dataFormatRules: (value) => !!value || i18n.tc("check.chk_outputFormatDiv"),
      outPutPatternRules: (value) => !!value || i18n.tc("check.chk_outputPattern"),
    },
    // 検索時点の日時
    year: "",
    today: "",
    nowTime: "",
    search: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    totalCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 選択された取引先
    suppliersSelected: "",
    // 取引先SID
    clientSid: "",
    // 選択された品質区分
    qualityDivSelected: "",
    // 選択された品番/品名
    nameSelected: "",
    // 選択された在庫有無
    inStockSelected: "01",
    //データ出力時ダイアログ(初期値)
    dataOutPutDialog: false,
    //選択されたデータ形式
    dataFormatSelected: "01",
    //出力単位
    outPutPatternSelected: "01",
    // メニュー
    openMenu: null,
    // 詳細ポップアップ
    dialogDetails: false,
    // ソート
    sortItem: "",
    sortOptions: {},
    //初期値(ソート処理用)
    initial: 0,
    // 初期データ
    defaultData: [],
    // ヘッダ
    headerItems: [
      // 品番
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCnCd",
        width: "15%",
        align: "left",
        sortable: true,
      },
      // ステータス
      {
        text: i18n.tc("label.lbl_status"),
        value: "status",
        width: "7%",
        align: "left",
        sortable: true,
      },
      // 入荷予定
      {
        text: i18n.tc("label.lbl_recieveQty"),
        value: "recieveQty",
        width: "7%",
        align: "right",
        sortable: true,
      },
      // 入庫待ち
      {
        text: i18n.tc("label.lbl_waitEnterQty"),
        value: "waitEnterQty",
        width: "7%",
        align: "right",
        sortable: true,
      },
      // 保管
      {
        text: i18n.tc("label.lbl_strageQty"),
        value: "strageQty",
        width: "7%",
        align: "right",
        sortable: true,
      },
      // 引当済
      {
        text: i18n.tc("label.lbl_reservedQty"),
        value: "reservedQty",
        width: "7%",
        align: "right",
        sortable: true,
      },
      // 出庫
      {
        text: i18n.tc("label.lbl_leaveWarehouseQty"),
        value: "leaveWarehouseQty",
        width: "7%",
        align: "right",
        sortable: true,
      },
      // 出荷済み
      {
        text: i18n.tc("label.lbl_leaveQty"),
        value: "leaveQty",
        width: "7%",
        align: "right",
        sortable: true,
      },
    ],
    inputList: [],
    // 検索結果
    stockList: [],
    // 取引先プルダウン
    supplierList: [],
    // 入荷ステータスプルダウン
    qualityDivList: [],
    // 在庫有無プルダウン
    inStockList: [],
    //データ形式プルダウン
    dataFormatList: [],
    //出力単位プルダウン
    outPutPatternList: [],
    suppliersSelectedName: "",
    lot: "",
    qualityDiv: "",
    itemName: "",
    itemStandardSid: "",
    // 品番品名プルダウン
    nameList: [],
    dummy_input: {
      productNm: "製品名",
      productCd: "a",
      productAcc: "製品付帯",
      productQty: "0",
      caseSu: "0",
      ballSu: "0",
      baraSu: "0",
      lot: "lot",
      expireDate: "2022/01/13",
    },
  }),
  methods: {
    openUpdateDelete(item) {
      if (this.$refs.form.validate()) {
        // // 選択した行のアイテム
        this.dialogDetails = true;
        this.editedIndex = this.inputList.indexOf(item);
        this.detailDialogData.suppliersSelected = this.inputList[this.editedIndex].clientSid;
        this.detailDialogData.suppliersSelectedName = this.getClientName();
        this.detailDialogData.qualityDiv = this.stockList[this.editedIndex].qualityDiv;
        this.detailDialogData.itemName = this.inputList[this.editedIndex].productName;
        this.detailDialogData.itemCd = this.inputList[this.editedIndex].productCnCdShow;
        this.detailDialogData.itemStandardSid = this.inputList[this.editedIndex].itemStandardSid;
      }
    },
    // テーブルの余白調整
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.apigetStock("get-search");
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.apigetStock("get-search");
    },
    // 初期化
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      this.getInitData();
    },
    // 初期データ取得
    getInitData() {
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(null)
      );
      const qualityList = getParameter.getCodeMst(appConfig.CODETYPE.IN_QUALITY_DIV);
      const dataFormatDivList = getParameter.getCodeMst(appConfig.CODETYPE.DATAFORMAT_DIV);
      const outPutPatternDivList = getParameter.getCodeMst(appConfig.CODETYPE.OUTPUTPATTERN_DIV);
      const inStockDivList = getParameter.getCodeMst(appConfig.CODETYPE.IN_STOCK_DIV);
      Promise.all([
        clientList,
        qualityList,
        dataFormatDivList,
        outPutPatternDivList,
        inStockDivList,
      ])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
          this.qualityDivList = result[1];
          this.dataFormatList = result[2];
          this.outPutPatternList = result[3];
          this.inStockList = result[4];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    // 取引先コードセット処理
    setSrhSupplier() {
      return commonFunction.getListSetName(this.supplierList, this.suppliersSelected);
    },
    getClientName() {
      return commonFunction.getListSetText(
        this.supplierList,
        this.inputList[this.editedIndex].clientSid
      );
    },
    // 商品コードセット処理
    checkProductCd() {
      return commonFunction.getListSetName(this.nameList, this.nameSelected);
    },

    // 在庫照会検索
    apigetStock(val) {
      var now = new Date();
      this.today = dateTimeHelper.toStringDate("YYYY/MM/DD", now);
      this.nowTime = dateTimeHelper.toStringDate("hh:mm", now);
      // 現在時刻を表示

      if (this.$refs.form.validate()) {
        const config = this.$httpClient.createGetApiRequestConfig();

        // ローディング画面表示ON
        this.loadingCounter = 1;

        // 取引先
        if (this.suppliersSelected) {
          config.params.clientSid = this.suppliersSelected;
          this.clientSid = this.suppliersSelected;
        }

        // 処理区分
        if (this.qualityDivSelected) {
          config.params.qualityDiv = this.qualityDivSelected;
        }

        // 品番/品名
        if (this.nameSelected) {
          config.params.itemStandardSid = this.nameSelected;
        }

        // 在庫有無
        if (this.inStockSelected) {
          config.params.inStock = this.inStockSelected;
        }

        // ソート
        if (this.sortItem != "") {
          config.params.reqComSortItem = this.sortItem;
        }

        if (val == "get-search") {
          config.params.reqComPageIndex = "1";
          this.page = 1;
        } else {
          // ページャー処理
          if (this.page >= 2 && this.inputList.length == 0) {
            config.params.reqComPageIndex = "1";
            this.page = 1;
          } else {
            config.params.reqComPageIndex = this.page;
          }
        }
        // ページング
        config.params.reqComPaginationFlg = "1";
        config.params.reqComPageLimit = this.itemsPerPage;
        // console.debug("apigetStock() Config", config);
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.BIZ_STOCK, config)
            .then((response) => {
              // console.debug("apigetStock() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                this.pageCount = jsonData.resCom.resComPagination.totalPage;
                this.totalCount = jsonData.resCom.resComPagination.totalRecord;
                const list = [];

                this.stockList = jsonData.resIdv.stocks;

                for (let i = 0; i < this.stockList.length; i++) {
                  list.push({
                    productCnCd: this.stockList[i].itemCd.substring(0, 50).trim(), // 品番
                    productCnCdShow: this.stockList[i].itemCd, // 品番
                    incidental: this.stockList[i].itemCd.slice(-50).trim(), // 品番付帯
                    productName: this.stockList[i].itemName, // 品名
                    status: this.stockList[i].qualityDivName, // ステータス
                    statusCode: this.stockList[i].qualityDiv, // ステータスコード
                    recieveQty: commonUtil.formatToCurrency(
                      Number(this.stockList[i].inScheduleCnt)
                    ), // 入荷予定数
                    waitEnterQty: commonUtil.formatToCurrency(Number(this.stockList[i].inCnt)), // 入庫待ち
                    strageQty: commonUtil.formatToCurrency(Number(this.stockList[i].stocksCnt)), // 在庫数
                    reservedQty: commonUtil.formatToCurrency(
                      Number(this.stockList[i].stocksAllocationCnt)
                    ), // 引当済
                    leaveWarehouseQty: commonUtil.formatToCurrency(
                      Number(this.stockList[i].warehouseOutCnt)
                    ), // 出庫
                    leaveQty: commonUtil.formatToCurrency(Number(this.stockList[i].outCnt)), // 出荷済
                    itemStandardSid: this.stockList[i].itemStandardSid,
                    clientSid: this.suppliersSelected, // 取引先SID
                  });
                }
                this.inputList = list;
                resolve(response);
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = "結果";
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                reject(resolve);
              }
            })
            .catch((resolve) => {
              console.error("apigetStock() Resolve", resolve);
              this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject(resolve);
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },

    //データ出力ボタン押下時
    getOutPut() {
      var now = new Date();
      this.today = dateTimeHelper.toStringDate("YYYY/MM/DD", now);
      this.nowTime = dateTimeHelper.toStringDate("hh:mm", now);
      // 現在時刻を表示

      if (this.$refs.form.validate()) {
        this.dataOutPutDialog = true;
      }
    },

    // 在庫情報出力画面：データ出力ボタン押下時
    outPutClick() {
      if (this.$refs.numIssueVali.validate()) {
        // ローディング画面表示ON
        this.loadingCounter = 1;

        const config = this.$httpClient.createRequestBodyConfig();

        config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SRG_001;

        //取引先
        config.reqIdv.clientSid = this.suppliersSelected;

        // 処理区分
        if (this.qualityDivSelected) {
          config.reqIdv.qualityDiv = this.qualityDivSelected;
        }

        // 品番/品名
        if (this.nameSelected) {
          config.reqIdv.itemStandardSid = this.nameSelected;
        }

        // 在庫有無
        if (this.inStockSelected) {
          config.reqIdv.inStock = this.inStockSelected;
        }

        //データ形式
        config.reqIdv.outputFileDiv = this.dataFormatSelected;

        //出力パターン
        config.reqIdv.outputPatternDiv = this.outPutPatternSelected;

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.STOCK_SLIP_FORMAT, config, appConfig.APP_CONFIG)
            .then((response) => {
              // // console.debug("btnSearch() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 正常時
              if (jsonData.resCom.resComCode == "000") {
                // 返却されたurlを共通関数へ呼び出し
                commonFunction.getLink(jsonData.resIdv.filePath);
                resolve(response);
              } else {
                // エラー時(件数0件またはapiエラー時)
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
              this.dataOutPutDialog = false;
            });
        });
      }
    },

    // 在庫情報出力画面：ポップアップ閉じる
    closeDetailsInfo() {
      this.dataOutPutDialog = false;
    },
    /**
     * プルダウンチェンジイベント
     */
    changeProductNm(value) {
      // 既存品番/品名を選択時
      let val = this.nameList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHint = val.text;
        this.search = val.text;
      }
      this.nameList = this.nameList.filter((v) => v.value == value);
    },

    /**
     * 数量詳細画面を開く
     */
    openDetailDialog(dummy_input, targetSupplierCode, targetArrivalScheduleNo) {
      // 詳細画面へ渡す値を設定します。
      this.detailDialogData = dummy_input;
      // 選択された列名
      this.detailDialogData.suppliersSelected = targetSupplierCode;
      this.detailDialogData.txt_arrivalScheduleNo = targetArrivalScheduleNo;
      this.isOpenDetailDialog = true;
    },
    // ダイアログオープン
    openPopup() {
      this.dialogDetails = true;
    },
    // ダイアログクローズ
    closePopup() {
      this.dialogDetails = false;
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // クリアフラグ
      this.clearFlg = true;
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
  },
  computed: {},
  watch: {
    //取引先変更時
    suppliersSelected() {
      //品番/品名リストクリア
      this.nameSelected = "";
      this.nameList = [];
    },
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    page: function (newValue) {
      //画面クリア時の場合
      if (this.clearFlg) {
        return;
      }
      this.page = newValue;
      this.apigetStock("");
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        //初期画面読み込み時
        if (this.initial == 0) {
          this.sortItem = "";
          this.initial = 1;
        }
        //画面クリア時の場合
        else if (this.clearFlg) {
          this.clearFlg = false;
          return;
        }
        //ソートが解除された場合
        else if (items.sortBy.length < 1) {
          this.sortItem = "";
          this.apigetStock("");
        }
        //ソート実施時
        else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_SRG_001_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.apigetStock("");
        }
      },
      deep: true,
    },

    search(val) {
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHint) {
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          const itemsBiz = getParameter.getItemsBizAddName2(
            this.suppliersSelected,
            dateTimeHelper.convertUTC(),
            val
          );

          Promise.all([itemsBiz])
            .then((result) => {
              // 画面の初期値を設定します。
              const list = [];
              this.nameList = list.concat(result[0]);
              this.nameList.unshift({ name: "", text: "", value: "" });
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.nameList.length = 0;
        }
      }
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.text-overflow {
  // v-tooltip
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.toatlNum {
  margin-left: 10px;
}

.nowTime {
  font-weight: bold;
  font-size: large;
}

.strageQty {
  font-size: medium;
}

::v-deep .v-dialog #detailsDialig .v-input__slot {
  width: 150%;
}

//選択項目枠変更用
// .search-autocomplete-supplierList {
//   width: 21rem;
// }
</style>
